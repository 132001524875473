import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns'
import { isEmpty, is } from 'ramda'
import React, { useEffect, useState, useCallback } from 'react'
import DatePicker from 'react-datepicker'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Chip } from '@/components/Chip/Chip'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/text-area'
import { AppRoutes } from '@/constants/appRoutes'
import {
  useModifyPostMutation,
  useModifyWebSearchPostMutation,
} from '@/store/api/Subjects/Subjects.query'
import type {
  PostsList,
  SocialMedia,
} from '@/store/api/Subjects/Subjects.types'
import { filterEmptyFields } from '@/utils/filterEmptyFields'
import type { modifyPostSchemaType } from '@/utils/schemas'
import { modifyPostSchema } from '@/utils/schemas'

import 'react-datepicker/dist/react-datepicker.css'
import {
  selectCurrentPlatform,
  selectEditPostFlags,
  selectSubjectId,
} from '@/store/api/Subjects/Subjects.selector'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { Switch } from '@/components/ui/switch'
import {
  clearEditPostSelectedFlags,
  setEditPostSelectedFlags,
} from '@/store/api/Subjects/Subjects.slice'

import { EditPostLoadFlagSheet } from './components/EditPostLoadFlagSheet'
import { useHasCurrentScreenToken } from './Guards/SubjectGuards'

import { setEditPostToken } from '@/store/api/Subjects/SubjectsGuards.slice'

export const EditPost = () => {
  useHasCurrentScreenToken('editPost')
  const { state } = useLocation()
  const dispatch = useDispatch()
  const currentData: PostsList = state

  useEffect(() => {
    dispatch(setEditPostSelectedFlags(currentData.flags))
  }, [])

  const socialMediaData: SocialMedia = useSelector(selectCurrentPlatform)
    ?.platformData

  const selectedEditPostFlag = useSelector(selectEditPostFlags)
  const id = socialMediaData.id
  const subjectId = useSelector(selectSubjectId)
  const postId = state.id

  const navigate = useNavigate()
  const [selectedType, setSelectedType] = useState<string>(currentData.type)
  const [selectedSentiment, setSelectedSentiment] = useState<string>(
    currentData.sentimentFlag,
  )

  const [startDate, setStartDate] = useState<Date | null>(
    currentData.date ? new Date(currentData.date) : null,
  )

  const [modifyPost, { isLoading: isLoadingModifyPost }] =
    useModifyPostMutation()
  const [modifyWebSearchPost, { isLoading: isLoadingModifyWebSearchPost }] =
    useModifyWebSearchPostMutation()

  const [selectedFile, setSelectedFile] = useState<File | undefined>()
  const [imagePreview, setImagePreview] = useState<string | null>(
    currentData.mediaUrl || null,
  )

  const onDropRejected = (e: any) => {
    if (
      !isEmpty(e) &&
      e[0].errors &&
      e[0].errors[0].code === 'file-too-large'
    ) {
      toast.error('File is too large')
    } else {
      toast.error('Invalid file type')
    }
  }

  const onDrop = useCallback(async (acceptedFiles: any) => {
    if (!isEmpty(acceptedFiles)) {
      const file = acceptedFiles[0]
      setSelectedFile(file)
      setImagePreview(URL.createObjectURL(file))
    }
  }, [])

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    onDropRejected,
    disabled: false,
    noClick: true,
    accept: {
      'image/png': ['.png', '.jpg', '.webp', '.jpeg'],
    },
    maxFiles: 1,
    maxSize: 5000000,
  })

  const handleModifyPost = async (data: any) => {
    const formattedDate = startDate
      ? format(startDate, 'MM-dd-yyyy').toString()
      : ''

    try {
      const formData = new FormData()
      if (selectedFile) {
        formData.append('image', selectedFile)
      }

      const dataObject: any = {
        content: data.content,
        link: data.link,
        videoLink: data.videoUrl,
        type: selectedType,
        sentimentFlag: selectedSentiment,
        flags: is(Array, selectedEditPostFlag)
          ? selectedEditPostFlag
          : selectedEditPostFlag === ''
            ? []
            : selectedEditPostFlag,
        flaggedText: data.flaggedText,
        flagReason: data.flagReason,
        sentimentReason: data.sentimentReason,
        date: formattedDate,
      }

      const filteredDataObject = filterEmptyFields(dataObject)
      formData.append('data', JSON.stringify(filteredDataObject))

      await modifyPost({
        formData,
        subjectId: subjectId || '',
        id: id,
        postId: postId,
      }).unwrap()

      toast.success('Post modified successfully.')
      navigate(AppRoutes.platformAndPostsEdition)
    } catch (e) {
      toast.error('Error modifying the Post.')
      console.error(e)
    }
  }

  const handleModifyWebSearchPost = async (data: any) => {
    try {
      const formData = new FormData()
      if (selectedFile) {
        formData.append('image', selectedFile)
      }

      const dataObject: any = {
        content: data.content,
        link: data.link,
        videoLink: data.videoLink,
        sentimentFlag: selectedSentiment,
        flags: is(Array, selectedEditPostFlag)
          ? selectedEditPostFlag
          : selectedEditPostFlag === ''
            ? []
            : selectedEditPostFlag,
        flaggedText: data.flaggedText,
        flagReason: data.flagReason,
        sentimentReason: data.sentimentReason,
      }
      const filteredDataObject = filterEmptyFields(dataObject)
      formData.append('data', JSON.stringify(filteredDataObject))

      await modifyWebSearchPost({
        formData,
        subjectId: subjectId || '',
        postId: postId,
      }).unwrap()

      toast.success('Web Search Post modified successfully.')
      navigate(AppRoutes.platformAndPostsEdition)
    } catch (e) {
      toast.error('Error modifying the Web Search Post.')
      console.error(e)
    }
  }

  const form = useForm<modifyPostSchemaType>({
    resolver: zodResolver(modifyPostSchema),
    defaultValues: {
      content: currentData.content || '',
      link: currentData.link || '',
      mediaUrl: currentData.mediaUrl !== null ? currentData.mediaUrl : '',
      videoLink: currentData.videoUrl !== null ? currentData.videoUrl : '',
      flags: selectedEditPostFlag,
      flaggedText: currentData.flaggedText,
      flagReason: currentData.flagReason !== null ? currentData.flagReason : '',
      sentimentReason:
        currentData.sentimentReason !== null ? currentData.sentimentReason : '',
    },
    mode: 'onChange',
  })

  const { handleSubmit, control, formState } = form

  const onSubmit = (data: any) => {
    if (
      socialMediaData.name === 'Adverse Media Posts' ||
      socialMediaData.name === 'Web/News Posts'
    ) {
      handleModifyWebSearchPost(data)
    } else {
      handleModifyPost(data)
    }
    dispatch(clearEditPostSelectedFlags())
  }

  const handleCancel = () => {
    dispatch(clearEditPostSelectedFlags())
    dispatch(setEditPostToken(false))
    navigate(AppRoutes.platformAndPostsEdition)
  }
  return (
    <WeScreen
      boxCustomPadding={16}
      useNextButton={false}
      rightSideHeaderContent={
        <div className="flex gap-3 items center">
          <Button variant={'secondary'} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            form="form"
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isValid}
            isLoading={isLoadingModifyPost || isLoadingModifyWebSearchPost}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form {...form}>
        <form className="flex flex-col gap-6">
          <Card className="flex flex-col p-4">
            <p className="text-neutralColor text-base font-medium mb-6">
              Post Data
            </p>

            <div className="mb-6">
              <FormItem className="w-full">
                <FormLabel>Type</FormLabel>
                <div className="flex justify-between">
                  <FormField
                    name="type"
                    disabled={
                      socialMediaData.name === 'Adverse Media Posts' ||
                      socialMediaData.name === 'Web/News Posts'
                    }
                    render={({ field }) => (
                      <FormItem className="flex h-12 p-3 rounded-2xl border border-neutralColor30 justify-between items-end w-[24%]">
                        <p className="font-medium">Like</p>
                        <FormControl>
                          <Switch
                            className="w-12 h-6"
                            {...field}
                            checked={selectedType === 'Like'}
                            onCheckedChange={(checked) => {
                              if (checked) setSelectedType('Like')
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="type"
                    disabled={
                      socialMediaData.name === 'Adverse Media Posts' ||
                      socialMediaData.name === 'Web/News Posts'
                    }
                    render={({ field }) => (
                      <FormItem className="flex h-12 p-3 rounded-2xl border border-neutralColor30 justify-between items-end w-[24%]">
                        <p className="font-medium">Post</p>
                        <FormControl>
                          <Switch
                            className="w-12 h-6"
                            {...field}
                            checked={selectedType === 'Post'}
                            onCheckedChange={(checked) => {
                              if (checked) setSelectedType('Post')
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="type"
                    disabled={
                      socialMediaData.name === 'Adverse Media Posts' ||
                      socialMediaData.name === 'Web/News Posts'
                    }
                    render={({ field }) => (
                      <FormItem className="flex h-12 p-3 rounded-2xl border border-neutralColor30 justify-between items-end w-[24%]">
                        <p className="font-medium">Repost</p>
                        <FormControl>
                          <Switch
                            className="w-12 h-6"
                            {...field}
                            checked={selectedType === 'Repost'}
                            onCheckedChange={(checked) => {
                              if (checked) setSelectedType('Repost')
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="type"
                    disabled={
                      socialMediaData.name === 'Adverse Media Posts' ||
                      socialMediaData.name === 'Web/News Posts'
                    }
                    render={({ field }) => (
                      <FormItem className="flex h-12 p-3 rounded-2xl border border-neutralColor30 justify-between items-end w-[24%]">
                        <p className="font-medium">Reply</p>
                        <FormControl>
                          <Switch
                            className="w-12 h-6"
                            {...field}
                            checked={selectedType === 'Reply'}
                            onCheckedChange={(checked) => {
                              if (checked) setSelectedType('Reply')
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
              </FormItem>
            </div>

            <div className="flex flex-wrap justify-between gap-y-6">
              <div className="flex flex-col w-[49%] gap-2 justify-end">
                <FormLabel>Date</FormLabel>
                {startDate && (
                  <DatePicker
                    disabled={
                      socialMediaData.name === 'Adverse Media Posts' ||
                      socialMediaData.name === 'Web/News Posts'
                    }
                    className="flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm focus-visible:outline-none"
                    selected={startDate ? startDate : new Date()}
                    onChange={(date: any) => setStartDate(date)}
                  />
                )}
              </div>
              <FormField
                control={control}
                name="link"
                render={({ field }) => (
                  <FormItem className="w-[49%]">
                    <FormLabel>Link</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="link" />
                  </FormItem>
                )}
              />
              <FormField
                name="mediaUrl"
                render={() => (
                  <FormItem className="w-[49%]">
                    <FormLabel>Media Image</FormLabel>
                    <div className="flex gap-4">
                      <FormControl className="flex-1">
                        <div
                          {...getRootProps()}
                          className="flex flex-col h-11 border border-dashed border-neutralColor30 rounded-md pl-3 justify-center"
                        >
                          <input {...getInputProps()} />
                          {selectedFile ? (
                            <div className="flex items-center gap-2">
                              <span className="text-sm">
                                {selectedFile.name}
                              </span>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setSelectedFile(undefined)
                                  setImagePreview(currentData.mediaUrl || null)
                                }}
                              >
                                Remove
                              </Button>
                            </div>
                          ) : (
                            <div className="flex items-center gap-2">
                              <p className="text-sm text-neutralColor60">
                                Drag & drop or click to select
                              </p>
                              <Button
                                type="button"
                                variant="secondary"
                                size="sm"
                                onClick={() => inputRef.current?.click()}
                              >
                                Choose File
                              </Button>
                            </div>
                          )}
                        </div>
                      </FormControl>
                      {imagePreview && (
                        <div className="w-11 h-11 rounded-md overflow-hidden">
                          <img
                            src={imagePreview}
                            alt="Preview"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      )}
                    </div>
                    <FormMessage data-name="mediaUrl" />
                  </FormItem>
                )}
              />
              <FormField
                name="videoLink"
                render={({ field }) => (
                  <FormItem className="w-[49%]">
                    <FormLabel>Video Link</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="videoLink" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="content"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Content</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage data-name="Content" />
                  </FormItem>
                )}
              />
            </div>
          </Card>
          <Card className="flex flex-col gap-6 p-4">
            <div className="flex flex-col gap-3">
              <p className="text-neutralColor text-base font-medium">
                Post Sentiment
              </p>
              <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                Select the report section where post will appear.
              </p>
            </div>
            <div>
              <FormItem className="w-full">
                <div className="flex justify-between">
                  <FormField
                    name="sentimentFlag"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2 h-[94px] p-3 rounded-2xl border border-neutralColor60 justify-start items-between w-[32%]">
                        <div className="flex justify-between items-center">
                          <p className="font-medium text-base text-neutralColor">
                            Negative
                          </p>
                          <FormControl>
                            <Switch
                              className="w-12 h-6"
                              {...field}
                              checked={
                                selectedSentiment === 'Negative' ||
                                socialMediaData.name === 'Adverse Media Posts'
                              }
                              onCheckedChange={(checked) => {
                                if (checked) setSelectedSentiment('Negative')
                              }}
                            />
                          </FormControl>
                        </div>

                        <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                          The post shows negative sentiment or dissatisfaction.
                        </p>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="sentimentFlag"
                    disabled={socialMediaData.name === 'Adverse Media Posts'}
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2 h-[94px] p-3 rounded-2xl border border-neutralColor60 justify-start items-between w-[32%]">
                        <div className="flex justify-between items-center">
                          <p className="font-medium text-base text-neutralColor">
                            Positive
                          </p>
                          <FormControl>
                            <Switch
                              className="w-12 h-6"
                              {...field}
                              checked={selectedSentiment === 'Positive'}
                              onCheckedChange={(checked) => {
                                if (checked) setSelectedSentiment('Positive')
                              }}
                            />
                          </FormControl>
                        </div>
                        <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                          The post reflects positive sentiment or approval.
                        </p>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="sentimentFlag"
                    disabled={socialMediaData.name === 'Adverse Media Posts'}
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2 h-[94px] p-3 rounded-2xl border border-neutralColor60 justify-start items-between w-[32%]">
                        <div className="flex justify-between items-center">
                          <p className="font-medium text-base text-neutralColor">
                            Neutral
                          </p>
                          <FormControl>
                            <Switch
                              className="w-12 h-6"
                              {...field}
                              checked={selectedSentiment === 'Neutral'}
                              onCheckedChange={(checked) => {
                                if (checked) setSelectedSentiment('Neutral')
                              }}
                            />
                          </FormControl>
                        </div>
                        <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                          The post has no strong positive or negative tone.
                        </p>
                      </FormItem>
                    )}
                  />
                </div>
              </FormItem>
            </div>
            <div>
              <FormField
                control={control}
                name="sentimentReason"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      {selectedSentiment === ''
                        ? 'Sentiment'
                        : selectedSentiment}
                      &nbsp;Reason
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        disabled={
                          selectedSentiment === '' ||
                          selectedSentiment === 'Positive' ||
                          selectedSentiment === 'Neutral'
                        }
                      />
                    </FormControl>
                    <FormMessage data-name="sentimentReason" />
                  </FormItem>
                )}
              />
            </div>
          </Card>
          <Card className="flex flex-col gap-6 p-4">
            <div className="flex flex-col gap-2">
              <p className="text-neutralColor text-base font-medium">
                Post Flag
              </p>
              <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                Add up to 2 Flags.
              </p>
            </div>
            <div className="flex items-center gap-3">
              <EditPostLoadFlagSheet currentPostFlags={currentData.flags} />

              {selectedEditPostFlag?.map((flag, index) => (
                <Chip blue text={flag} key={index} isTag />
              ))}
            </div>

            <FormField
              control={control}
              name="flagReason"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Flag Reason</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage data-name="flagReason" />
                </FormItem>
              )}
            />
          </Card>
        </form>
      </Form>
    </WeScreen>
  )
}
