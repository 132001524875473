import React from 'react'

import { useMediumDesktopQuery } from '@/hooks/useMediumDesktopQuery'
import useMediumMobileMediaQuery from '@/hooks/useMediumMobileMediaQuery'
import { useSmallDesktopQuery } from '@/hooks/useSmallDesktopQuery'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import useTabletMediaQuery from '@/hooks/useTabletMediaQuery'
import { formatDate } from '@/utils/date'

interface PostCardContentTypes {
  reason?: string
  postType?: string
  postDate?: string
  imageUrl?: string
  content?: string
  link?: string
  platform: string
  cardHeigth: number
  videoUrl?: string
}

export const PostCardContent = ({
  postDate,
  content,
  imageUrl,
  link,
  platform,
  reason,
  cardHeigth,
  videoUrl,
}: PostCardContentTypes) => {
  const isSmallMobile = useSmallMobileMediaQuery()
  const isMobile = useMediumMobileMediaQuery()
  const isTablet = useTabletMediaQuery()
  const isSmallDesktop = useSmallDesktopQuery()
  const isMediumDesktop = useMediumDesktopQuery()
  return (
    <div
      className={
        isSmallDesktop
          ? 'mt-3 flex flex-col flex-1'
          : 'lg:gap-3 mt-3 flex flex-col flex-1'
      }
    >
      <div
        className={isSmallDesktop ? 'px-2 mb-6' : 'lg:px-4 px-2 lg:mb-0 mb-6'}
        style={{ height: isSmallMobile || isMobile || isTablet ? 65 : 60 }}
      >
        <p className={`text-sm font-medium line-clamp-3`}>{content}</p>
      </div>

      <div
        className={`
          ${
            isSmallDesktop || isMediumDesktop
              ? 'flex mb-6 justify-center items-center'
              : `flex lg:mb-0 mb-6 justify-center items-center `
          } ${!reason && 'flex-1'}
            `}
        style={{
          height:
            isSmallMobile ||
            isMobile ||
            isTablet ||
            isSmallDesktop ||
            isMediumDesktop
              ? cardHeigth / 4
              : cardHeigth / 2,
        }}
      >
        {imageUrl && (
          <img
            src={imageUrl}
            alt={`${platform} post img`}
            className="object-cover w-full"
            style={{
              maxHeight:
                isSmallMobile ||
                isMobile ||
                isTablet ||
                isSmallDesktop ||
                isMediumDesktop
                  ? cardHeigth / 3
                  : cardHeigth / 2,
            }}
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.style.display = 'none'
            }}
          />
        )}
        {videoUrl && (
          <video
            src={videoUrl}
            className="object-cover w-full"
            controls
            onError={(e: React.SyntheticEvent<HTMLVideoElement>) => {
              e.currentTarget.style.display = 'none'
            }}
            style={{
              maxHeight:
                isSmallMobile ||
                isMobile ||
                isTablet ||
                isSmallDesktop ||
                isMediumDesktop
                  ? cardHeigth / 3
                  : cardHeigth / 2,
            }}
          >
            <track
              kind="captions"
              src={videoUrl}
              label="Captions"
              onError={(e: React.SyntheticEvent<HTMLTrackElement>) => {
                e.currentTarget.style.display = 'none'
              }}
            />
          </video>
        )}
      </div>

      <div
        className={`${`flex justify-between pb-1 lg:px-4 px-2 border-b border-neutralColor30`} ${
          !reason && 'mb-4 border-b-0'
        }`}
      >
        <p className="text-xs text-neutralColor font-medium">
          {postDate && formatDate(postDate, 'hh:mm a - MMM dd, yyyy')}
        </p>
        <a
          href={link}
          className="text-xs font-medium text-dynamicBlue"
          target="_blank"
        >
          View Post
        </a>
      </div>

      {reason && (
        <div
          className={isSmallDesktop ? 'px-2 mt-2' : 'lg:px-4 px-2 lg:mt-0 mt-2'}
        >
          <p className="text-xs font-medium text-neutralColor">{reason}</p>
        </div>
      )}
    </div>
  )
}
