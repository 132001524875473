export interface ProfilesRequest {
  subjectId: string
}

export enum EProfileFindingsStatus {
  CHECKED = 'checked',
  REJECTED = 'rejected',
  PENDING = 'pending',
  AUTO_CHECKED = 'auto-checked',
  LOAD_BY_REQUESTER = 'load-by-requester',
}

export enum ESocialMedia {
  LINKEDIN = 'LinkedIn',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  PINTEREST = 'Pinterest',
  TIKTOK = 'TikTok',
  TWITTER = 'Twitter',
  REDDIT = 'Reddit',
  WEBSEARCH = 'WebSearch',
}

export interface IResult {
  url: string
  description: string
  status: EProfileFindingsStatus
  reviewedBy: string[]
  platform: ESocialMedia
}

export interface ProfilesResponse {
  profiles: IResult[]
  emails: IEmail[]
  phones: IPhone[]
  addresses: IAddressResult[]
  digitalFootprints: IDigitalFootprintResult[]
  career: ICareer[]
  education: IEducation[]
  skills: ISkills[]
}

export interface IEmail {
  email: string
  status: EEmailStatus
  type: EEmailType
}

export enum EEmailType {
  PERSONAL = 'personal',
  WORK = 'professional',
}

export interface IPhone {
  phone: string
  status: EPhoneStatus
}

export interface IAddressResult {
  address_line_2?: string
  continent?: string
  country?: string
  first_seen?: string
  full_address?: string
  geo?: string
  last_seen?: string
  locality?: string
  metro?: string
  name?: string
  num_sources?: number
  postal_code?: string
  region?: string
  street_address?: string
  status: EAddressStatus
}

export enum EPhoneStatus {
  CHECKED = 'checked',
  POSSIBLE = 'possible',
}

export enum EEmailStatus {
  CHECKED = 'checked',
  POSSIBLE = 'possible',
}

export enum EAddressStatus {
  CHECKED = 'checked',
  POSSIBLE = 'possible',
}

export enum EDigitalFootprintStatus {
  PENDING = 'pending',
  AUTO_CHECKED = 'auto-checked',
  REJECTED = 'rejected',
  CHECKED = 'checked',
}

export interface ISkills {
  name: string
  passedSkillAssessment: boolean
  endorsementsCount: number
}

export interface IEducation {
  startDate: {
    year: number
    month: number
    day: number
  }
  endDate: {
    year: number
    month: number
    day: number
  }
  title: string
  grade: string
  school: string
  description: string
  schoolUrl: string
  fieldOfStudy: string
}

export interface ICareer {
  title: string
  company: string
  startDate: {
    year: number
    month: number
    day: number
  }
  endDate: {
    year: number
    month: number
    day: number
  }
  description: string
  companyLogoUrl: string
  companyLinkedinURL: string
  companyStaffCountRange: string
}

export interface IDigitalFootprintResult {
  url: string
  description: string
  userName: string
  platform: string
  status: EDigitalFootprintStatus
  image?: string
  isWebSearch?: boolean
}

export interface UploadProfilesRequest {
  subjectId: string
  profiles: {
    status: EProfileFindingsStatus
    url: string
    platform: ESocialMedia
  }
}

export interface ScrapeProfilesRequest {
  subjectId: string
}

export interface SelectedProfile {
  id: string
  url: string
  name:
    | 'Instagram'
    | 'Facebook'
    | 'Twitter'
    | 'TikTok'
    | 'LinkedIn'
    | 'Pinterest'
    | 'Reddit'
    | ''
  isPredefined?: boolean
}

export enum ESocialMedias {
  INSTAGRAM = 'Instagram',
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
  TIKTOK = 'TikTok',
  LINKED_IN = 'LinkedIn',
  PINTEREST = 'Pinterest',
  REDDIT = 'Reddit',
}

export interface UpdateDigitalFootprintRequest {
  subjectId: string
  digitalFootprint: {
    url: string
    userName: string
    platform: string
    status: EDigitalFootprintStatus
    image?: string
    isWebSearch?: boolean
    description?: string
  }
}
