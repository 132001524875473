import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import emptyAvatar from '@/assets/emptyAvatar.png'
import { Button } from '@/components/ui/button'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { selectReportInfo } from '@/store/api/Admin/Admin.selector'
import { useScrapeProfilesMutation } from '@/store/api/Scrape/Scrape.query'
import { useLazyGetSubjectQuery } from '@/store/api/Subjects/Subjects.query'
import { setSubjectInfo } from '@/store/api/Subjects/Subjects.slice'

import { ReportDetailsHeader } from '../Subject/components/ReportDetailsHeader'
import { DigitalFootprintsCard } from '../Subject/DigitalFootprintsCard'

import { FoundProfilesCard } from './components/FoundProfilesCard'
import ProfileUploader from './components/ProfileUpload'

export const ScrapeFlow = () => {
  const reportInfo = useSelector(selectReportInfo).reportInfo
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [getSubjectData, { data, isLoading: isLoadingGetSubjectData }] =
    useLazyGetSubjectQuery()

  const [scrapeProfiles, { isLoading }] = useScrapeProfilesMutation()

  const handleScrapeProfiles = async () => {
    const result = await scrapeProfiles({ subjectId: reportInfo.subjectId })
    if ('error' in result) {
      toast.error('Error al iniciar el scraping')
    } else {
      toast.success('Scraping iniciado correctamente')
      navigate(AppRoutes.reports)
    }
  }

  useEffect(() => {
    if (reportInfo?.subjectId) {
      getSubjectData({ subjectId: reportInfo.subjectId }).then((response) => {
        if (response?.data?.info) {
          dispatch(setSubjectInfo(response.data.info))
        }
      })
    }
  }, [reportInfo, dispatch, getSubjectData])

  return (
    <WeScreen
      isLoading={isLoadingGetSubjectData}
      boxCustomGap={16}
      boxCustomPadding={16}
      useNextButton={false}
    >
      {data && reportInfo && (
        <ReportDetailsHeader
          fullName={data?.info.personData.name || 'Nameless'}
          profileImage={data?.info.personData.profilePicUrl || emptyAvatar}
          id={data?.info.id}
          subjectId={reportInfo.subjectId}
          wecheckScraped={true}
        />
      )}
      <FoundProfilesCard subjectId={reportInfo.subjectId} />
      <ProfileUploader
        subjectId={reportInfo.subjectId}
        personData={data?.info.personData}
      />

      <Button onClick={handleScrapeProfiles} isLoading={isLoading}>
        Start Scraping
      </Button>

      <DigitalFootprintsCard subjectId={reportInfo.subjectId} />
    </WeScreen>
  )
}
