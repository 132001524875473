import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface ChipTypes {
  isTag?: boolean
  green?: boolean
  blue?: boolean
  primaryBlue?: boolean
  yellow?: boolean
  text?: string
  disabled?: boolean
  red?: boolean
  icon?: React.ReactNode
  dashed?: boolean
  black?: boolean
  cream?: boolean
  grey?: boolean
  mustard?: boolean
  orange?: boolean
  greenYellow?: boolean
  purple?: boolean
  brown?: boolean
  board?: boolean
  lightGreen?: boolean
  lightBlue?: boolean
}

export const Chip = ({
  isTag,
  green,
  blue,
  primaryBlue,
  yellow,
  red,
  text,
  disabled,
  icon,
  dashed,
  black,
  cream,
  grey,
  mustard,
  orange,
  greenYellow,
  purple,
  brown,
  board,
  lightGreen,
  lightBlue,
}: ChipTypes) => {
  const colorMap = {
    blue: {
      bgColor: '#C6F5FF',
      textColor: '#037797',
      border: 'transparent',
    },
    green: {
      bgColor: '#C8FFD4',
      textColor: '#1E924D',
      border: 'transparent',
    },
    primaryBlue: {
      bgColor: '#087FFF',
      textColor: '#F5F5F5',
      border: '#087FFF',
    },
    disabled: {
      bgColor: 'neutral-200',
      textColor: 'neutral-400',
      border: 'transparent',
    },
    default: {
      bgColor: '',
      textColor: '',
      border: '',
    },
    yellow: {
      bgColor: '#FFFDCB',
      textColor: '#DFB014',
      border: 'transparent',
    },
    red: {
      bgColor: weCheckPalette.alertRedBase,
      textColor: weCheckPalette.dynamicRed,
      border: weCheckPalette.alertRedBase,
    },
    dashed: {
      bgColor: 'transparent',
      textColor: weCheckPalette.neutralColor60,
      border: weCheckPalette.neutralColor60,
    },
    black: {
      bgColor: '#5A4F4D',
      textColor: weCheckPalette.neutralColor,
      border: '#5A4F4D',
    },
    cream: {
      bgColor: '#d6dac8',
      textColor: '#505552',
      border: '#d6dac8',
    },
    grey: {
      bgColor: '#a8b2aa',
      textColor: '#505552',
      border: '#a8b2aa',
    },
    mustard: {
      bgColor: '#fbde81',
      textColor: '#ae974b',
      border: '#fbde81',
    },
    orange: {
      bgColor: '#f5e8dd',
      textColor: '#e58f46',
      border: '#f5e8dd',
    },
    greenYellow: {
      bgColor: '#dbcc95',
      textColor: '#b7930c',
      border: '#dbcc95',
    },
    purple: {
      bgColor: '#d3cedf',
      textColor: '#7753cd',
      border: '#d3cedf',
    },
    brown: {
      bgColor: '#eccdb4',
      textColor: '#c1773b',
      border: '#eccdb4',
    },
    board: {
      bgColor: '#ac7d88',
      textColor: '#773847',
      border: '#ac7d88',
    },
    lightGreen: {
      bgColor: '#f5f5dc',
      textColor: '#979700',
      border: '#f5f5dc',
    },
    lightBlue: {
      bgColor: '#F0F8FF',
      textColor: '#4682B4',
      border: '#B0E0E6',
    },
  }

  const getChipColor = () => {
    if (blue) return 'blue'
    if (green) return 'green'
    if (primaryBlue) return 'primaryBlue'
    if (yellow) return 'yellow'
    if (red) return 'red'
    if (dashed) return 'dashed'
    if (disabled) return 'disabled'
    if (black) return 'black'
    if (cream) return 'cream'
    if (grey) return 'grey'
    if (mustard) return 'mustard'
    if (orange) return 'orange'
    if (greenYellow) return 'greenYellow'
    if (purple) return 'purple'
    if (brown) return 'brown'
    if (board) return 'board'
    if (lightGreen) return 'lightGreen'
    if (lightBlue) return 'lightBlue'
    return 'default'
  }

  const { bgColor, textColor, border } = colorMap[getChipColor()]

  return (
    <div
      className={`flex ${
        isTag ? 'h-6 px-3 py-1 rounded-[200px]' : 'h-5 px-1 py-0.5 rounded'
      } items-center justify-center gap-1`}
      style={{
        backgroundColor: bgColor,
        border: `0.5px ${dashed ? 'dashed' : 'solid'} ${border}`,
      }}
    >
      <p
        className="text-xs font-medium tracking-tight text-nowrap"
        style={{ color: textColor }}
      >
        {text}
      </p>
      {icon && icon}
    </div>
  )
}
