import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import emptyAvatar from '@/assets/avatarEmptyState.svg'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { selectReportInfo } from '@/store/api/Admin/Admin.selector'
import { useLazyGetSubjectQuery } from '@/store/api/Subjects/Subjects.query'
import { setSubjectInfo } from '@/store/api/Subjects/Subjects.slice'

import { ReplacePdfPopUp } from './components/ReplacePdfPopUp'
import { ReportDetailsHeader } from './components/ReportDetailsHeader'
import { DigitalFootprintsCard } from './DigitalFootprintsCard'
import DigitalFootprintLoader from './LoadDigitalFootprint'
import { ProfileAddress } from './ProfileAddress'
import { ProfileEmails } from './ProfileEmails'

export const ReportProfileFindings = () => {
  const navigate = useNavigate()
  const reportInfo = useSelector(selectReportInfo).reportInfo
  const dispatch = useDispatch()

  const [getSubjectData, { data, isLoading: isLoadingGetSubjectData }] =
    useLazyGetSubjectQuery()

  useEffect(() => {
    if (reportInfo?.subjectId) {
      getSubjectData({ subjectId: reportInfo.subjectId }).then((response) => {
        if (response?.data?.info) {
          dispatch(setSubjectInfo(response.data.info))
        }
      })
    }
  }, [reportInfo, dispatch, getSubjectData, data])

  const onPressDowload = () => {
    window.open(data?.info.pdf, '_blank')
  }

  const onClickBack = () => {
    navigate(AppRoutes.socialMedia)
  }

  return (
    <WeScreen
      isLoading={isLoadingGetSubjectData}
      boxCustomGap={32}
      boxCustomPadding={16}
      useNextButton={false}
      onClickBack={onClickBack}
    >
      {data && reportInfo && (
        <ReportDetailsHeader
          fullName={data?.info?.personData?.name || 'Nameless'}
          data={data}
          profileImage={data?.info?.personData?.profilePicUrl || emptyAvatar}
          showActions
          primaryButtonTitle="Download PDF"
          primaryButtonOnClick={onPressDowload}
          secondaryButtonChildren={
            <ReplacePdfPopUp id={data?.info.id || ''} key={data?.info.id} />
          }
          id={data?.info.id}
          subjectId={reportInfo.subjectId}
          showCopyId
        />
      )}
      <DigitalFootprintsCard subjectId={reportInfo.subjectId} />
      <DigitalFootprintLoader subjectId={reportInfo.subjectId} />
      <ProfileAddress subjectId={reportInfo.subjectId} />
      <ProfileEmails subjectId={reportInfo.subjectId} />
    </WeScreen>
  )
}
