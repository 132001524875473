import React, { useState } from 'react'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useUpdateDigitalFootprintMutation } from '@/store/api/Scrape/Scrape.query'
import { EDigitalFootprintStatus } from '@/store/api/Scrape/Scrape.types'
import type { PersonData } from '@/store/api/Subjects/Subjects.types'

const DigitalFootprintLoader = ({
  subjectId,
}: {
  subjectId: string
  personData?: PersonData
}) => {
  const [profileUrl, setProfileUrl] = useState('')
  const [description, setDescription] = useState('')
  const [userName, setUserName] = useState('')
  const [platform, setPlatform] = useState('')
  const [image, setImage] = useState('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfileUrl(event.target.value)
  }

  const [uploadProfiles, { isLoading }] = useUpdateDigitalFootprintMutation()

  const handleSubmit = async () => {
    try {
      const result = await uploadProfiles({
        subjectId: subjectId,
        digitalFootprint: {
          url: profileUrl,
          platform: platform || '',
          status: EDigitalFootprintStatus.CHECKED,
          description: description || '',
          userName: userName || '',
          image: image || '',
          isWebSearch: false,
        },
      })

      if ('error' in result) {
        toast.error('Error al subir el perfil')
      } else {
        toast.success('Perfil subido exitosamente')
      }
      setProfileUrl('')
      setDescription('')
      setUserName('')
      setPlatform('')
      setImage('')
    } catch (error) {
      console.error('Error en la mutación:', error)
      setProfileUrl('')
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <h2>Upload Digital Footprint</h2>

      <div className="flex w-full gap-3">
        <Input
          type="text"
          name="url"
          placeholder="URL"
          className="w-96"
          value={profileUrl}
          onChange={handleInputChange}
        />
        <Input
          type="text"
          name="description"
          placeholder="Description"
          className="w-96"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Input
          type="text"
          name="userName"
          placeholder="User Name"
          className="w-96"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <Input
          type="text"
          name="platform"
          placeholder="Platform"
          className="w-96"
          value={platform}
          onChange={(e) => setPlatform(e.target.value)}
        />
        <Input
          type="text"
          name="image"
          placeholder="Image"
          className="w-96"
          value={image}
          onChange={(e) => setImage(e.target.value)}
        />
      </div>

      <Button onClick={handleSubmit} isLoading={isLoading}>
        Add Digital Footprint
      </Button>
    </div>
  )
}

export default DigitalFootprintLoader
