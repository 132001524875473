import { ArrowRight2 } from 'iconsax-react'
import React from 'react'
import { toast } from 'sonner'

import { Chip } from '@/components/Chip/Chip'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from '@/components/ui/sheet'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useChangeReportStatusMutation } from '@/store/api/Admin/Admin.query'
import {
  ESocialCheckStatus,
  type ReportItem,
} from '@/store/api/Admin/Admin.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export const ReportChangeStatusSheet = ({ data }: { data: ReportItem }) => {
  const [selectedStatus, setSelectedStatus] = React.useState<string | null>(
    null,
  )
  const [changeReportStatus, { isLoading }] = useChangeReportStatusMutation()

  const handleUpdateStatus = async (
    selectedStatus: string | null,
    subjectId: string,
  ) => {
    try {
      await changeReportStatus({
        subjectId: subjectId,
        status: selectedStatus as ESocialCheckStatus,
      }).unwrap()
      toast.success('Changes saved successfully')
    } catch (error) {
      toast.error('Error saving changes.')
      catchWithSentry(error)
    }
  }

  return (
    <Sheet>
      <SheetTrigger className="w-full">
        <Button variant="ghost" asChild>
          <Chip
            icon={<ArrowRight2 size={16} color={weCheckPalette.neutralColor} />}
            isTag
            text={
              data.status === ESocialCheckStatus.InQueue
                ? 'In Queue'
                : data.status === ESocialCheckStatus.Cancelled
                  ? 'Cancelled'
                  : data.status === ESocialCheckStatus.Complete
                    ? 'Complete'
                    : data.status === ESocialCheckStatus.PendingReview
                      ? 'Pending Review'
                      : data.status ===
                          ESocialCheckStatus.PendingComplianceSigned
                        ? 'Pending Compliance Signed'
                        : data.status === ESocialCheckStatus.ErrorPdf
                          ? 'Error PDF'
                          : data.status === ESocialCheckStatus.NotReleased
                            ? 'Not Released'
                            : data.status ===
                                ESocialCheckStatus.PendingToScrapProfiles
                              ? 'Pending To Scrap'
                              : data.status ===
                                  ESocialCheckStatus.ScrapeInProgress
                                ? 'Scrape In Progress'
                                : data.status === 'PendingConfirmProfiles'
                                  ? 'Pending Confirm Profiles'
                                  : data.status ===
                                      ESocialCheckStatus.IaAnalysisInProgress
                                    ? 'IA Analysis In Progress'
                                    : data.status
            }
            yellow={data.status === ESocialCheckStatus.InQueue}
            green={data.status === ESocialCheckStatus.Complete}
            blue={data.status === ESocialCheckStatus.PendingReview}
            red={
              data.status === ESocialCheckStatus.ErrorPdf ||
              data.status === ESocialCheckStatus.Error
            }
            greenYellow={
              data.status === ESocialCheckStatus.PendingComplianceSigned
            }
            grey={data.status === ESocialCheckStatus.NotReleased}
            black={data.status === ESocialCheckStatus.Cancelled}
            brown={data.status === ESocialCheckStatus.PendingToScrapProfiles}
            board={data.status === ESocialCheckStatus.ScrapeInProgress}
            purple={data.status === 'PendingConfirmProfiles'}
            lightBlue={data.status === ESocialCheckStatus.IaAnalysisInProgress}
          />
        </Button>
      </SheetTrigger>
      <SheetContent className="flex flex-col gap-5 overflow-scroll">
        <SheetHeader className="flex flex-col gap-2">
          <SheetTitle className="text-xl font-medium text-neutralColor">
            Change Report Status
          </SheetTitle>
          <SheetDescription className="text-base font-normal text-neutralColor60">
            You can change the report status here
          </SheetDescription>
        </SheetHeader>
        <div className="flex flex-col gap-5">
          <RadioGroup onValueChange={setSelectedStatus} className="gap-4">
            {Object.values(ESocialCheckStatus).map((status) => (
              <div className="flex items-center justify-between" key={status}>
                <Label htmlFor={status}>{status}</Label>
                <RadioGroupItem value={status} id={status} />
              </div>
            ))}
          </RadioGroup>

          <Button
            variant={'default'}
            onClick={async () =>
              handleUpdateStatus(selectedStatus, data.subjectId)
            }
            disabled={!selectedStatus}
            isLoading={isLoading}
          >
            Send
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}
