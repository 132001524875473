import type {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { AddSquare, More } from 'iconsax-react'
import { isEmpty } from 'ramda'
import * as React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Chip } from '@/components/Chip/Chip'
import { EmptyState } from '@/components/EmptyState/EmptyState'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
} from '@/components/ui/select'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { AppRoutes } from '@/constants/appRoutes'
import type { listPaginatedOfTopicsResponse } from '@/store/api/Topics/Topics.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { ModifyTopicPopUp } from './ModifyTopicPopUp'
import { TopicsPopUp } from './TopicsPopUp'

export function TopicsTable({
  data,
}: {
  data?: listPaginatedOfTopicsResponse
}) {
  const navigate = useNavigate()
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
      cell: ({ row }) => (
        <div className="text-neutral-800 text-base font-normal">
          {row.getValue('name') || 'Nameless'}
        </div>
      ),
    },
    {
      accessorKey: 'isActive',
      header: 'Status',
      cell: ({ row }) => (
        <div className="w-min">
          <Chip
            text={row.getValue('isActive') ? 'Active' : 'Inactive'}
            isTag
            green={row.getValue('isActive')}
            red={!row.getValue('isActive')}
          />
        </div>
      ),
    },
    {
      accessorKey: 'enterprise',
      header: 'Enterprise',
      cell: ({ row }) => (
        <div className="text-neutral-800 text-base font-normal">
          {row.getValue('enterprise') || 'No Enterprise'}
        </div>
      ),
    },
    {
      id: 'actions',
      cell: ({ row }) => (
        <div className="flex justify-end">
          <Select>
            <SelectTrigger
              className="w-10 h-10 border-none shadow-none p-1 "
              hiddenArrow
            >
              <More color={weCheckPalette.neutralColor} size={30} />
            </SelectTrigger>
            <SelectContent className="w-[224px]">
              <SelectGroup>
                <SelectLabel>Actions</SelectLabel>
                <SelectSeparator />
                <TopicsPopUp
                  translations={data?.info.list[row.index].translations}
                  description={data?.info.list[row.index].description}
                  prompt={data?.info.list[row.index].prompt}
                />
                <ModifyTopicPopUp
                  id={data?.info.list[row.index]._id}
                  name={data?.info.list[row.index].name}
                  enterprise={data?.info.list[row.index].enterprise}
                  translations={data?.info.list[row.index].translations}
                />
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      ),
    },
  ]

  const table = useReactTable({
    data: data?.info.list ?? [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter,
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  if (isEmpty(data)) {
    return <EmptyState text="We will show here the topics when there are any" />
  }

  return (
    <div className="flex flex-col w-full p-4 max-xl:gap-4 gap-6">
      <div className="flex items-center justify-between">
        <Input
          placeholder="Filter by any element..."
          value={globalFilter}
          onChange={(event) => {
            const value = event.target.value
            setGlobalFilter(value)
          }}
          className="w-96 h-10"
        />
        <Button
          onClick={() => navigate(AppRoutes.newTopic)}
          className="flex gap-2"
        >
          Add Topic
          <AddSquare size="22" color={weCheckPalette.staticWhite} />
        </Button>
      </div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className="bg-backgroundHighlight hover:bg-muted/50 "
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className="text-sm font-medium tracking-tighter"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="text-center">
                <EmptyState text="There seem to be results for your search" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="flex space-x-2 justify-end">
        <Button
          className="h-10 px-4 py-2 rounded-lg border border-primary30 bg-primaryColor5"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <p className="text-neutralColor text-base font-normal">Previous</p>
        </Button>
        <Button
          className="h-10 px-4 py-2 rounded-lg border border-primary30 bg-primaryColor5"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <p className="text-neutralColor text-base font-normal">Next</p>
        </Button>
      </div>
    </div>
  )
}
