import { SelectContent } from '@radix-ui/react-select'
import type { ColumnDef, VisibilityState } from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'
import { toast } from 'sonner'

import { EmptyState } from '@/components/EmptyState/EmptyState'
import { Loader } from '@/components/Loader/Loader'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import {
  useGetProfilesQuery,
  useUploadProfilesMutation,
} from '@/store/api/Scrape/Scrape.query'
import type { IResult } from '@/store/api/Scrape/Scrape.types'
import { EProfileFindingsStatus } from '@/store/api/Scrape/Scrape.types'

export function FoundProfilesCard({ subjectId }: { subjectId: string }) {
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')
  const { data: profiles, isLoading: isLoadingProfiles } = useGetProfilesQuery({
    subjectId: subjectId,
  })

  const [uploadProfiles] = useUploadProfilesMutation()

  const handleStatusChange = async (
    status: EProfileFindingsStatus,
    item: IResult,
  ) => {
    try {
      const result = await uploadProfiles({
        subjectId: subjectId,
        profiles: {
          status,
          url: item.url,
          platform: item.platform,
        },
      })

      if ('error' in result) {
        toast.error('Error al actualizar el estado del perfil')
      } else {
        toast.success('Estado del perfil actualizado correctamente')
      }
    } catch (error) {
      toast.error('Error al actualizar el estado del perfil')
    }
  }
  const columns: ColumnDef<any>[] = [
    {
      accessorKey: 'url',
      header: 'Url',
      id: 'url',
      cell: ({ row }) => (
        <a href={row.original.url} target="_blank" rel="noopener noreferrer">
          {row.original.url}
        </a>
      ),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      id: 'description',
      cell: ({ row }) => row.original.description || 'Without description',
    },
    {
      accessorKey: 'status',
      header: 'Status',
      id: 'status',
      cell: ({ row }) => (
        <Select
          value={row.original.status}
          onValueChange={async (event) =>
            handleStatusChange(event as EProfileFindingsStatus, row.original)
          }
        >
          <SelectTrigger className="w-min space-x-2 ">
            <SelectValue placeholder="Select user role">
              {row.original.status}
            </SelectValue>
          </SelectTrigger>
          <SelectContent className="w-[224px] bg-secondary">
            <SelectGroup>
              <SelectLabel>Social Media Status</SelectLabel>
              <SelectSeparator />
              <SelectItem value={EProfileFindingsStatus.CHECKED}>
                Check
              </SelectItem>
              <SelectItem value={EProfileFindingsStatus.PENDING}>
                Pending
              </SelectItem>
              <SelectItem value={EProfileFindingsStatus.REJECTED}>
                Rejected
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      ),
    },
    {
      accessorKey: 'platform',
      header: 'Platform',
      id: 'platform',
      cell: ({ row }) => row.original.platform || 'Without description',
    },
  ]

  const table = useReactTable({
    data: (profiles?.profiles as unknown as any[]) || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter,
      columnVisibility,
      rowSelection,
    },
  })

  if (isLoadingProfiles) {
    return (
      <div className="flex h-96 items-center">
        <div className="flex justify-center w-full">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col w-full p-4 gap-2">
      <div className="flex items-center py-4 justify-between">
        <Input
          placeholder="Filter by any element..."
          value={globalFilter}
          onChange={(event) => {
            const value = event.target.value
            setGlobalFilter(value)
          }}
          className="h-8 border border-neutralColor30 rounded-lg w-[376px]"
        />
      </div>
      <Table className="text-xs">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className="text-sm font-medium tracking-tighter"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <EmptyState text="There seem to be results for your search" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="space-x-2">
        <Button
          className="h-5 px-4 py-2 rounded-lg border border-primary30 bg-primaryColor5"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <p className="text-neutralColor text-sm font-normal">Previous</p>
        </Button>
        <Button
          className="h-5 px-4 py-2 rounded-lg border border-primary30 bg-primaryColor5"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <p className="text-neutralColor text-sm font-normal">Next</p>
        </Button>
      </div>
    </div>
  )
}
