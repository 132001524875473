import { api, RTKTags } from '@/store/api'

import Env from '../../../env/env'

import type {
  ProfilesRequest,
  ProfilesResponse,
  ScrapeProfilesRequest,
  UpdateDigitalFootprintRequest,
  UploadProfilesRequest,
} from './Scrape.types'

export const ScrapeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfiles: builder.query<ProfilesResponse, ProfilesRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/profiles-findings`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => {
        return response.info
      },
      providesTags: [RTKTags.profileFindings],
    }),
    uploadProfiles: builder.mutation<void, UploadProfilesRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/profiles-findings`,
        method: 'PATCH',
        body: request.profiles,
      }),
      invalidatesTags: [RTKTags.profileFindings],
    }),
    scrapeProfiles: builder.mutation<void, ScrapeProfilesRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/scrape-profiles`,
        method: 'POST',
      }),
    }),
    updateDigitalFootprint: builder.mutation<
      void,
      UpdateDigitalFootprintRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/profiles-findings/digital-footprints`,
        method: 'PATCH',
        body: {
          url: request.digitalFootprint.url,
          userName: request.digitalFootprint.userName,
          platform: request.digitalFootprint.platform,
          status: request.digitalFootprint.status,
          image: request.digitalFootprint.image,
          isWebSearch: request.digitalFootprint.isWebSearch,
          description: request.digitalFootprint.description,
        },
      }),
      invalidatesTags: [RTKTags.profileFindings],
    }),
  }),
})
export const {
  useGetProfilesQuery,
  useUploadProfilesMutation,
  useScrapeProfilesMutation,
  useUpdateDigitalFootprintMutation,
} = ScrapeApi
