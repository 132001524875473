import { RTKTags, api } from '@/store/api'

import Env from '../../../env/env'

import type {
  getSubjectResponse,
  getSubjectRequest,
  getSubjectPostsResponse,
  getSubjectPostsRequest,
  deletePostRequest,
  deleteSocialMediaRequest,
  modifySummaryRequest,
  deleteWebSearchPostRequest,
  changeReportTopicsRequest,
} from './Subjects.types'

export const subjectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubject: builder.query<getSubjectResponse, getSubjectRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}`,
        method: 'GET',
        params: {},
      }),
      providesTags: [RTKTags.subjects, RTKTags.reports],
    }),
    updateSocialMediaInfo: builder.mutation<
      void,
      { formData: FormData; subjectId: string; id: string }
    >({
      query: ({ formData, subjectId, id }) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${subjectId}/social-media/${id}`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    addNewSocialMedia: builder.mutation<
      void,
      { formData: FormData; subjectId: string }
    >({
      query: ({ formData, subjectId }) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${subjectId}/social-media`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    getSubjectPosts: builder.query<
      getSubjectPostsResponse,
      getSubjectPostsRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/posts`,
        method: 'GET',
        params: {
          filterBy: request.filterBy,
          page: request.page,
          pageSize: 5000,
          platform: request.platform,
          socialMediaId: request.socialMediaId,
        },
      }),
      providesTags: [RTKTags.subjects],
    }),
    deletePost: builder.mutation<void, deletePostRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/social-media/${request.id}/posts/${request.postId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    deleteSocialMedia: builder.mutation<void, deleteSocialMediaRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/social-media/${request.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    createNewPost: builder.mutation<
      void,
      { formData: FormData; subjectId: string; id: string }
    >({
      query: ({ formData, subjectId, id }) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${subjectId}/social-media/${id}/posts`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    modifyPost: builder.mutation<
      void,
      { formData: FormData; subjectId: string; id: string; postId: string }
    >({
      query: ({ formData, subjectId, id, postId }) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${subjectId}/social-media/${id}/posts/${postId}`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    createNewWebSearchPost: builder.mutation<
      void,
      { formData: FormData; subjectId: string }
    >({
      query: ({ formData, subjectId }) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${subjectId}/web-search`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    modifyWebSearchPost: builder.mutation<
      void,
      { formData: FormData; subjectId: string; postId: string }
    >({
      query: ({ formData, subjectId, postId }) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${subjectId}/web-search/${postId}`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    deleteWebSearchPost: builder.mutation<void, deleteWebSearchPostRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/web-search/${request.postId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    modifyPersonData: builder.mutation<
      void,
      { formData: FormData; subjectId: string }
    >({
      query: ({ formData, subjectId }) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${subjectId}/person-data`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    modifySummary: builder.mutation<void, modifySummaryRequest>({
      query: (request: modifySummaryRequest) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/summary`,
        method: 'PATCH',
        body: {
          executiveAim: request.executiveAim,
          executiveSummary: request.executiveSummary,
          riskIndicators: request.riskIndicators,
          sentimentAnalysis: request.sentimentAnalysis,
          redFlags: request.redFlags,
          webNewsFindings: request.webNewsFindings,
          digitalBehavior: request.digitalBehavior,
          digitalInfluence: request.digitalInfluence,
          recommendationsImprovements: request.recommendationsImprovements,
          recommentationsConclusion: request.recommentationsConclusion,
          financialMisconduct: request.financialMisconduct || undefined,
          legalDispute: request.legalDispute || undefined,
          criminalRecords: request.criminalRecords || undefined,
          legalJudgments: request.legalJudgments || undefined,
          positiveMentions: request.positiveMentions || undefined,
          assessment: request.assessment || undefined,
          complianceDisclaimer: request.complianceDisclaimer || undefined,
          adverseMediaSummary: request.adverseMediaSummary || undefined,
          recommendationsInsights: request.recommendationsInsights || undefined,
        },
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    changeReportTopics: builder.mutation<void, changeReportTopicsRequest>({
      query: (request: changeReportTopicsRequest) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/topics`,
        method: 'PATCH',
        body: {
          topics: request.topics,
          topicsIds: request.topicsIds,
        },
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
  }),
})

export const {
  useLazyGetSubjectQuery,
  useUpdateSocialMediaInfoMutation,
  useAddNewSocialMediaMutation,
  useLazyGetSubjectPostsQuery,
  useDeletePostMutation,
  useDeleteSocialMediaMutation,
  useCreateNewPostMutation,
  useModifyPostMutation,
  useCreateNewWebSearchPostMutation,
  useModifyWebSearchPostMutation,
  useModifyPersonDataMutation,
  useModifySummaryMutation,
  useDeleteWebSearchPostMutation,
  useChangeReportTopicsMutation,
} = subjectsApi
