import React, { useState } from 'react'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useUploadProfilesMutation } from '@/store/api/Scrape/Scrape.query'
import {
  ESocialMedias,
  EProfileFindingsStatus,
} from '@/store/api/Scrape/Scrape.types'
import type { PersonData } from '@/store/api/Subjects/Subjects.types'

const socialMediaPatterns: Record<ESocialMedias, RegExp> = {
  [ESocialMedias.FACEBOOK]:
    /^(https?:\/\/)?(www\.)?(facebook\.com\/(profile\.php\?id=\d+|[A-Za-z0-9._-]+|p\/[A-Za-z0-9._-]+|people\/[A-Za-z0-9._-]+\/\d+)\/?|web\.facebook\.com\/p\/[A-Za-z0-9._-]+\/?)$/,
  [ESocialMedias.TWITTER]:
    /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9._-]+\/?$/,
  [ESocialMedias.INSTAGRAM]:
    /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9._-]+\/?$/,
  [ESocialMedias.LINKED_IN]:
    /^(https?:\/\/)?(www\.)?(linkedin\.com\/in\/[A-Za-z0-9._-]+\/?|linkedin\.com\/company\/[A-Za-z0-9._-]+\/?)$/,
  [ESocialMedias.TIKTOK]:
    /^(https?:\/\/)?(www\.)?tiktok\.com\/@[A-Za-z0-9._-]+\/?$/,
  [ESocialMedias.PINTEREST]:
    /^(https?:\/\/)?(www\.)?pinterest\.com\/[A-Za-z0-9._-]+\/?$/,
  [ESocialMedias.REDDIT]:
    /^(https?:\/\/)?(www\.)?reddit\.com\/user\/[A-Za-z0-9._-]+\/?$/,
}

const ProfileUploader = ({
  subjectId,
}: {
  subjectId: string
  personData?: PersonData
}) => {
  const [profileUrl, setProfileUrl] = useState('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfileUrl(event.target.value)
  }

  const [uploadProfiles, { isLoading }] = useUploadProfilesMutation()

  const handleSubmit = async () => {
    let detectedPlatform: ESocialMedias | null = null

    // Detectar la red social usando los patrones
    for (const [platform, pattern] of Object.entries(socialMediaPatterns)) {
      if (pattern.test(profileUrl)) {
        detectedPlatform = platform as ESocialMedias
        break
      }
    }

    if (!detectedPlatform) {
      toast.error('No se pudo detectar la red social de la URL proporcionada')
      return
    }

    try {
      const result = await uploadProfiles({
        subjectId: subjectId,
        profiles: {
          url: profileUrl,
          platform: detectedPlatform as any,
          status: EProfileFindingsStatus.CHECKED,
        },
      })

      if ('error' in result) {
        toast.error('Error al subir el perfil')
      } else {
        toast.success('Perfil subido exitosamente')
      }
      setProfileUrl('')
    } catch (error) {
      console.error('Error en la mutación:', error)
      setProfileUrl('')
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <h2>Upload Profiles</h2>

      <div className="flex w-full gap-6">
        <Input
          type="text"
          name="url"
          placeholder="URL"
          className="w-96"
          value={profileUrl}
          onChange={handleInputChange}
        />
      </div>

      <Button onClick={handleSubmit} isLoading={isLoading}>
        Add Profile
      </Button>
    </div>
  )
}

export default ProfileUploader
